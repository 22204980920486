import ViewContainer from '@components/viewContainer';
import React from 'react';
import ImageGallery from 'react-image-gallery';
import images from '../../images.json';

const Images = (): React.ReactElement => {
    return (
        <ViewContainer>
            <h2 className="title">Images</h2>
            <ImageGallery items={images} />
        </ViewContainer>
    );
};

export default Images;
